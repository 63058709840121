import { RetailMetrics } from "@technis/shared";

export const BEHAVIOR_DATA = {
  PRODUCT: [{ label: "ALL", value: "IPhone" }],
  GENDER_FEMALE: [{ label: "ALL", value: 22 }],
  [RetailMetrics.CAPTURE_RATE]: [{ label: "ALL", value: 11 }],
};

export const COMFORT_DATA = {
  GOOD_EQ: [{ label: "ALL", value: 11 }],
  AVERAGE_EQ: [{ label: "ALL", value: 22 }],
  CRITICAL_EQ: [{ label: "ALL", value: 33 }],
};
