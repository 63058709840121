/* eslint-disable max-lines */
import { useQuery } from "@apollo/client";
import {
  type AlertLog,
  ConditionCriterions,
  type Device,
  type FilterCondition,
  isCondition,
  KpiRetailGroupType,
  type KpiRetailQueryFilter,
  KpiRetailQueryTarget,
  type Organization,
  type Passage,
  type RetailMetrics,
  type Widget,
  WidgetTypes,
  type Zone,
} from "@technis/shared";
import { type DatabaseReference, getDatabase, onValue, ref, type Unsubscribe } from "firebase/database";
import { type TFunction } from "i18next";
import { type Dictionary } from "lodash";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import { useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { useDeepCompareEffect } from "react-use";

import { type AlertLogNode } from "@graphql/query/log/getLogs";

import { ClientFetchPolicy } from "@common/enums/network/network";

import { translation } from "@lang/translation";

import { PASSAGES_BY_IDS, type PassagesByIdsResult } from "@services/passageService";
import { ZONES_BY_IDS, type ZonesByIdsResult } from "@services/zoneService";

import { type RetailDataResult, type RetailDataResultElement } from "../typings";

const { DEVICE, PASSAGE, ZONE } = KpiRetailQueryTarget;

interface FirebaseHookProps {
  deviceIds?: string[];
  filters?: (FilterCondition | KpiRetailQueryFilter<FilterCondition>)[];
  ignoredFilters?: ConditionCriterions[];
  installationId?: number;
  organizationId?: number;
  passageIds?: number[];
  retailMetrics: RetailMetrics[];
  skip?: boolean;
  widgetType?: Widget["type"];
  zoneIds?: number[];
}

const applyFiltersOnIds = <T>(
  ids: T[],
  filters: (FilterCondition | KpiRetailQueryFilter<FilterCondition>)[],
  criterion: ConditionCriterions,
): T[] =>
  filters.reduce((accumulator, filter) => {
    if (isCondition(filter) && filter.criterion === criterion) {
      return accumulator.filter((id) => (filter.values as unknown as T[]).includes(id));
    }

    return accumulator;
  }, ids);

type SingleData = {
  values: Partial<RetailDataResult>;
  loading: boolean;
};

export const useFirebaseRetailSingleDataHook = ({
  deviceIds = [],
  zoneIds = [],
  passageIds = [],
  installationId: filterInstallationId,
  organizationId,
  retailMetrics = [],
  skip = false,
  filters = [],
  widgetType = WidgetTypes.KPIS,
  ignoredFilters = [],
}: FirebaseHookProps): SingleData => {
  const [values, setValues] = useState<Partial<RetailDataResult>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filteredZoneIds, setZoneIds] = useState(zoneIds);
  const [filteredPassageIds, setPassageIds] = useState(passageIds);
  const [filteredDeviceIds, setDeviceIds] = useState(deviceIds);
  const { current: metrics } = useRef(retailMetrics);

  const { data: zoneResult } = useQuery<ZonesByIdsResult>(ZONES_BY_IDS, {
    notifyOnNetworkStatusChange: false,
    variables: { zoneIds: filteredZoneIds },
    skip: !filteredZoneIds?.length,
    fetchPolicy: ClientFetchPolicy.CACHE_FIRST,
  });

  const { data: passageResult } = useQuery<PassagesByIdsResult>(PASSAGES_BY_IDS, {
    notifyOnNetworkStatusChange: false,
    variables: { passageIds: filteredPassageIds },
    skip: !filteredPassageIds?.length,
    fetchPolicy: ClientFetchPolicy.CACHE_FIRST,
  });

  const installationId = get(zoneResult, "zonesByIds[0].installationId", filterInstallationId);
  const zoneMap = keyBy(get(zoneResult, "zonesByIds", []), "id");
  const passageMap = keyBy(get(passageResult, "passagesByIds", []), "id");

  const targetMap: Record<KpiRetailQueryTarget, Dictionary<Passage> | Dictionary<Zone> | Dictionary<Device>> = {
    [DEVICE]: {},
    [PASSAGE]: passageMap,
    [ZONE]: zoneMap,
  };

  useDeepCompareEffect(() => {
    const newZoneIds = (ignoredFilters || []).includes(ConditionCriterions.ZONES)
      ? zoneIds
      : applyFiltersOnIds(zoneIds, filters, ConditionCriterions.ZONES);
    const newPassageIds = (ignoredFilters || []).includes(ConditionCriterions.PASSAGES)
      ? passageIds
      : applyFiltersOnIds(passageIds, filters, ConditionCriterions.PASSAGES);
    const newDeviceIds = (ignoredFilters || []).includes(ConditionCriterions.DEVICES)
      ? deviceIds
      : applyFiltersOnIds(deviceIds, filters, ConditionCriterions.DEVICES);

    if (!isEqual(newZoneIds, filteredZoneIds) && !isEmpty(newZoneIds)) {
      setZoneIds(newZoneIds);
    }
    if (!isEqual(newPassageIds, filteredPassageIds) && !isEmpty(newPassageIds)) {
      setPassageIds(newPassageIds);
    }
    if (!isEqual(newDeviceIds, filteredDeviceIds) && !isEmpty(newDeviceIds)) {
      setDeviceIds(newDeviceIds);
    }
  }, [zoneIds, passageIds, deviceIds, filters, filteredZoneIds, filteredDeviceIds, filteredPassageIds, ignoredFilters]);

  useDeepCompareEffect(() => {
    if (skip) {
      return;
    }

    const db = getDatabase();

    const references: {
      entityId: string | number;
      metric: RetailMetrics;
      ref: DatabaseReference;
      target: KpiRetailQueryTarget;
      unsubscribe: () => void;
    }[] = [];

    const subscribe = (entityIds: (string | number)[], target: KpiRetailQueryTarget): void =>
      entityIds.forEach((entityId) => {
        metrics.forEach((metric, index) => {
          const retailDataReference = ref(
            db,

            `/${process.env.ENV}/${process.env.FIREBASE_LIVE_KPI_ENDPOINT}/${organizationId}/${installationId}/${target}s/${entityId}/${metric}`,
          );
          references.push({
            metric,
            ref: retailDataReference,
            entityId,
            target,
            unsubscribe: onValue(
              retailDataReference,
              (snapshot) => {
                const value = snapshot.val();
                const key = `${metric}_${index}`;
                setValues((previous) => ({
                  ...previous,
                  [key]: [
                    ...(previous[key as RetailMetrics] || [])
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      .filter((event: RetailDataResultElement<number>) => event.label !== targetMap[target]?.[entityId]?.name),
                    {
                      label: targetMap[target]?.[entityId]?.name || KpiRetailGroupType.ALL,
                      value,
                    },
                  ],
                }));
                setIsLoading(false);
              },
              console.error,
            ),
          });
        });
      });

    if (!isEmpty(filteredDeviceIds)) {
      subscribe(filteredDeviceIds, DEVICE);
    }
    if (!isEmpty(filteredZoneIds) && !isEmpty(zoneMap)) {
      subscribe(filteredZoneIds, ZONE);
    }
    if (!isEmpty(filteredPassageIds) && !isEmpty(passageMap)) {
      subscribe(filteredPassageIds, PASSAGE);
    }

    return (): void => references.forEach((reference) => reference.unsubscribe());
  }, [
    installationId,
    organizationId,
    filteredZoneIds,
    filteredDeviceIds,
    filteredPassageIds,
    zoneMap,
    passageMap,
    skip,
    metrics,
    widgetType,
    zoneResult?.zonesByIds,
  ]);

  return { values, loading: isLoading };
};

type MultiData = {
  values: Partial<RetailDataResult>;
  loading: boolean;
};

export const useFirebaseRetailMultiDataHook = ({
  deviceIds = [],
  zoneIds = [],
  passageIds = [],
  installationId: filterInstallationId,
  organizationId,
  retailMetrics = [],
  skip = false,
  filters = [],
  widgetType = WidgetTypes.KPIS,
  ignoredFilters = [],
}: FirebaseHookProps): MultiData => {
  const [values, setValues] = useState<Partial<RetailDataResult>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filteredZoneIds, setZoneIds] = useState(zoneIds);
  const [filteredPassageIds, setPassageIds] = useState(passageIds);
  const [filteredDeviceIds, setDeviceIds] = useState(deviceIds);
  const { current: metrics } = useRef(retailMetrics);

  const { data: zoneResult } = useQuery<ZonesByIdsResult>(ZONES_BY_IDS, {
    notifyOnNetworkStatusChange: false,
    variables: { zoneIds: filteredZoneIds },
    skip: !filteredZoneIds?.length,
    fetchPolicy: ClientFetchPolicy.CACHE_FIRST,
  });

  const { data: passageResult } = useQuery<PassagesByIdsResult>(PASSAGES_BY_IDS, {
    notifyOnNetworkStatusChange: false,
    variables: { passageIds: filteredPassageIds },
    skip: !filteredPassageIds?.length,
    fetchPolicy: ClientFetchPolicy.CACHE_FIRST,
  });

  const installationId = get(zoneResult, "zonesByIds[0].installationId", filterInstallationId);

  const zoneMap = keyBy(get(zoneResult, "zonesByIds", []), "id");
  const passageMap = keyBy(get(passageResult, "passagesByIds", []), "id");

  const getInstallationForEntity = (entityId: number | string, target: KpiRetailQueryTarget): number | undefined => {
    switch (target) {
      case KpiRetailQueryTarget.ZONE: {
        return zoneMap[entityId]?.installationId ?? installationId;
      }
      case KpiRetailQueryTarget.PASSAGE: {
        return passageMap[entityId]?.installationId ?? installationId;
      }
      case KpiRetailQueryTarget.DEVICE: {
        return installationId;
      }
    }
  };

  const targetMap: Record<KpiRetailQueryTarget, Dictionary<Passage> | Dictionary<Zone> | Dictionary<Device>> = {
    [DEVICE]: {},
    [PASSAGE]: passageMap,
    [ZONE]: zoneMap,
  };

  useDeepCompareEffect(() => {
    const newZoneIds = (ignoredFilters || []).includes(ConditionCriterions.ZONES)
      ? zoneIds
      : applyFiltersOnIds(zoneIds, filters, ConditionCriterions.ZONES);
    const newPassageIds = (ignoredFilters || []).includes(ConditionCriterions.PASSAGES)
      ? passageIds
      : applyFiltersOnIds(passageIds, filters, ConditionCriterions.PASSAGES);
    const newDeviceIds = (ignoredFilters || []).includes(ConditionCriterions.DEVICES)
      ? deviceIds
      : applyFiltersOnIds(deviceIds, filters, ConditionCriterions.DEVICES);

    if (!isEqual(newZoneIds, filteredZoneIds) && !isEmpty(newZoneIds)) {
      setZoneIds(newZoneIds);
    }
    if (!isEqual(newPassageIds, filteredPassageIds) && !isEmpty(newPassageIds)) {
      setPassageIds(newPassageIds);
    }
    if (!isEqual(newDeviceIds, filteredDeviceIds) && !isEmpty(newDeviceIds)) {
      setDeviceIds(newDeviceIds);
    }
  }, [zoneIds, passageIds, deviceIds, filters, filteredZoneIds, filteredDeviceIds, filteredPassageIds, ignoredFilters]);

  useDeepCompareEffect(() => {
    if (skip) {
      return;
    }

    const db = getDatabase();

    const references: {
      entityId: string | number;
      metric: RetailMetrics;
      ref: DatabaseReference;
      target: KpiRetailQueryTarget;
    }[] = [];
    const unsubscribeFunctions: Unsubscribe[] = [];

    const subscribe = (entityIds: (string | number)[], target: KpiRetailQueryTarget): void =>
      entityIds.forEach((entityId) => {
        metrics.forEach((metric) => {
          const entityInstallationId = getInstallationForEntity(entityId, target);
          const retailDataReference = ref(
            db,

            `/${process.env.ENV}/${process.env.FIREBASE_LIVE_KPI_ENDPOINT}/${organizationId}/${entityInstallationId}/${target}s/${entityId}/${metric}`,
          );
          references.push({ metric, ref: retailDataReference, entityId, target });
        });
      });

    if (!isEmpty(filteredDeviceIds)) {
      subscribe(filteredDeviceIds, DEVICE);
    }
    if (!isEmpty(filteredZoneIds)) {
      subscribe(filteredZoneIds, ZONE);
    }
    if (!isEmpty(filteredPassageIds)) {
      subscribe(filteredPassageIds, PASSAGE);
    }

    references.forEach(({ metric, ref, entityId, target }, index) =>
      unsubscribeFunctions.push(
        onValue(
          ref,
          (snapshot) => {
            const value = snapshot.val();
            setValues((previous) => ({
              ...previous,
              [`${metric}_${index}`]: [
                {
                  label: targetMap[target]?.[entityId]?.name || KpiRetailGroupType.ALL,
                  value,
                },
              ],
            }));
            setIsLoading(false);
          },
          console.error,
        ),
      ),
    );

    return (): void => unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  }, [
    installationId,
    organizationId,
    filteredZoneIds,
    filteredDeviceIds,
    filteredPassageIds,
    skip,
    metrics,
    widgetType,
    zoneResult?.zonesByIds,
  ]);

  return { values, loading: isLoading };
};

type AlertLogReference = {
  ref: DatabaseReference;
  unsubscribe: () => void;
  zoneId: Zone["id"];
};

type AlertLogHook = {
  loading: boolean;
  zoneResult: ZonesByIdsResult | undefined;
  alertLogNodes: AlertLogNode[];
};

export const useFirebaseAlertLogHook = ({
  organizationId,
  skip = false,
  zoneIds = [],
  t,
}: {
  filters?: (FilterCondition | KpiRetailQueryFilter<FilterCondition>)[];
  ignoredFilters?: ConditionCriterions[];
  organizationId: Organization["id"];
  skip: boolean;
  t: TFunction;
  zoneIds?: Zone["id"][];
}): AlertLogHook => {
  const [alertLogs, setAlertLogs] = useState<AlertLog[]>([]);
  const [alertLogNodes, setAlertLogNodes] = useState<AlertLogNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const references: AlertLogReference[] = [];

  const db = getDatabase();
  const subscribe = (zoneIds: Zone["id"][]): void =>
    zoneIds.forEach((zoneId) => {
      const retailDataReference = ref(
        db,

        `/${process.env.ENV}/liveAlerts/${organizationId}/${zoneId}`,
      );
      references.push({
        ref: retailDataReference,
        zoneId,
        unsubscribe: onValue(
          retailDataReference,
          (snapshot) => {
            const values = snapshot.val();
            if (!isEmpty(values)) {
              const alertLogs: AlertLog[] = Object.values(values);
              setAlertLogs((previous) => [...previous.filter((log) => log.zoneId !== zoneId), ...alertLogs]);
            }

            setIsLoading(false);
          },
          console.error,
        ),
      });
    });

  const { data: zoneResult } = useQuery<ZonesByIdsResult>(ZONES_BY_IDS, {
    notifyOnNetworkStatusChange: false,
    variables: { zoneIds },
    skip: !zoneIds?.length,
    fetchPolicy: "cache-first",
  });

  useDeepCompareEffect(() => {
    if (skip || isEmpty(zoneIds)) {
      return;
    }

    if (!isEmpty(zoneIds)) {
      subscribe(zoneIds);
    }

    return (): void => {
      references.forEach((reference) => reference.unsubscribe());
      setAlertLogs([]);
      setIsLoading(true);
    };
  }, [organizationId, skip, zoneIds]);

  useDeepCompareEffect(() => {
    if (!zoneResult || !alertLogs) {
      return;
    }
    const zoneMap = keyBy(get(zoneResult, "zonesByIds", []), "id");

    const alertLogNodes: AlertLogNode[] = alertLogs.map((alertLog) => ({
      node: { ...alertLog, zone: { name: alertLog.zoneId ? zoneMap[alertLog.zoneId]?.name : t(translation.common.deletedZone) } },
    }));
    setAlertLogNodes(alertLogNodes);
  }, [zoneResult, alertLogs]);

  return { alertLogNodes, loading: isLoading, zoneResult };
};
