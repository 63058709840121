/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloProvider } from "@apollo/client";
import { initializeApp } from "firebase/app";
import React, { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { GATracking } from "@namespaces/googleTracking";
import { SegmentTracking } from "@namespaces/segmentTracking";
import { TechnisTracking } from "@namespaces/technisTracking";

import { api, initApolloClient } from "@api/apollo";

import { COOKIE_AUTH_VIEW_AS_USER, COOKIE_DOMAIN, removeCookie } from "@common/helpers/cookie";

import { DebugTools } from "@components/DebugMenu/DebugTools";

import { AppRoutes } from "@routes/AppRoutes";

import { persistor, store } from "@store/store";

import { ErrorBoundary } from "@views/ErrorBoundary";

// eslint-disable-next-line import/no-unassigned-import
require("../styles/index.scss");
// eslint-disable-next-line import/no-unassigned-import
require("../styles/fonts/fonts.css");

const configFirebase = {
  apiKey: process.env.FIREBASE_AUTH_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  storageBucket: process.env.GCLOUD_STORAGE_BUCKET,
};

initializeApp(configFirebase);

window.addEventListener("beforeunload", () => removeCookie(COOKIE_AUTH_VIEW_AS_USER, { domain: COOKIE_DOMAIN }));

const App = (): JSX.Element | null => {
  const [isLoadingApollo, setIsLoadingApollo] = useState(true);

  useEffect(() => {
    TechnisTracking.startConnection();
    GATracking.init();
    SegmentTracking.init();
    initApolloClient().finally(() => setIsLoadingApollo(false));
    return () => TechnisTracking.endConnection();
  }, []);

  return !isLoadingApollo && api.client ? (
    <ApolloProvider client={api.client}>
      {process.env.SHOW_DEBUG_TOOLS ? <DebugTools /> : undefined}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <Provider store={store}>
        <ErrorBoundary>
          <PersistGate loading={null} persistor={persistor}>
            <CookiesProvider>
              <AppRoutes />
            </CookiesProvider>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
    </ApolloProvider>
  ) : null;
};

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(<App />);
