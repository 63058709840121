import { useEffect } from "react";

import { GATracking } from "@namespaces/googleTracking";
import { SegmentTracking } from "@namespaces/segmentTracking";
import { TechnisTracking } from "@namespaces/technisTracking";
import { Tracking, TRACKING_EVENTS } from "@namespaces/tracking";

export const useTracking = (): {
  trackView(topic: string, name: string): void;
  trackClick(...props: Parameters<typeof Tracking.trackClick>): void;
  trackToggle(...props: Parameters<typeof Tracking.trackToggle>): void;
  trackSelect(...props: Parameters<typeof Tracking.trackSelect>): void;
  trackingEvents: typeof TRACKING_EVENTS;
} => {
  const trackView = (topic: string, name: string): void => {
    TechnisTracking.trackViewActivity({ name, topic });
    GATracking.trackViewActivity(window.location.pathname);
    SegmentTracking.trackViewActivity(topic, name, window.location.pathname);
  };

  const trackClick = (...props: Parameters<typeof Tracking.trackClick>): void => {
    Tracking.trackClick(...props);
  };

  const trackToggle = (...props: Parameters<typeof Tracking.trackToggle>): void => {
    Tracking.trackToggle(...props);
  };

  const trackSelect = (...props: Parameters<typeof Tracking.trackSelect>): void => {
    Tracking.trackSelect(...props);
  };

  return { trackView, trackClick, trackToggle, trackSelect, trackingEvents: TRACKING_EVENTS };
};

export const useViewTracking = (topic: string, name: string): void => {
  const { trackView } = useTracking();

  useEffect(() => {
    trackView(name, topic);
  }, [name, topic, trackView]);
};
