import { type Dispatch, type SetStateAction, useCallback, useState } from "react";

interface useBooleanReturnValue {
  setToFalse: () => void;
  setToTrue: () => void;
  setValue: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
  value: boolean;
}

export const useBoolean = (initialValue = false): useBooleanReturnValue => {
  const [isTrue, setIsTrue] = useState(initialValue);

  const setToTrue = useCallback(() => {
    setIsTrue(true);
  }, []);

  const setToFalse = useCallback(() => {
    setIsTrue(false);
  }, []);

  const toggle = useCallback(() => {
    setIsTrue((currentValue) => !currentValue);
  }, []);

  return { value: isTrue, setToTrue, setToFalse, toggle, setValue: setIsTrue };
};
